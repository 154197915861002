import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const Metaverse: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Metaverse',
  viewBox: '0 0 41 32',
  path: (
    <path
      d="M40.615 22.843c0 2.089-1.051 3.942-2.671 5.451-1.618 1.505-3.871 2.74-6.501 3.642-0.115 0.041-0.248 0.064-0.387 0.064-0.659 0-1.194-0.535-1.194-1.194 0-0.020 0-0.041 0.002-0.061l-0 0.003v-11.974c0-0.514 0.325-0.972 0.809-1.138 0.763-0.261 1.478-0.549 2.14-0.859-3.302-1.846-7.705-3.551-12.506-3.551-4.8 0-9.204 1.706-12.505 3.551 0.661 0.31 1.375 0.598 2.139 0.859 0.475 0.168 0.809 0.612 0.809 1.135 0 0.001 0 0.002 0 0.003v-0 11.961c0.001 0.021 0.002 0.046 0.002 0.071 0 0.659-0.534 1.194-1.194 1.194-0.139 0-0.272-0.024-0.396-0.067l0.008 0.003c-2.63-0.901-4.882-2.135-6.5-3.641-1.621-1.509-2.672-3.362-2.672-5.451v-12.025c0-3.356 2.673-6.071 6.258-7.876 3.657-1.839 8.626-2.943 14.049-2.943s10.393 1.104 14.049 2.944c3.585 1.804 6.258 4.519 6.258 7.877v12.023zM7.327 5.095c-3.332 1.676-4.938 3.77-4.938 5.726 0 1.218 0.607 2.475 1.905 3.685 0.33 0.308 0.7 0.607 1.108 0.896 3.612-2.218 8.93-4.581 14.906-4.581s11.294 2.363 14.905 4.581c0.409-0.289 0.779-0.59 1.109-0.896 1.3-1.21 1.905-2.466 1.905-3.685 0-1.956-1.606-4.049-4.938-5.726-3.262-1.642-7.849-2.69-12.981-2.69s-9.721 1.049-12.981 2.69zM38.226 15.999c-0.090 0.089-0.182 0.177-0.276 0.264l-0.007 0.007c-1.449 1.35-3.408 2.481-5.69 3.35v9.431c1.686-0.726 3.065-1.588 4.068-2.523 1.3-1.209 1.905-2.466 1.905-3.684v-6.846zM2.389 15.999v6.846c0 1.218 0.607 2.475 1.905 3.684 1.004 0.935 2.38 1.797 4.068 2.523v-9.431c-2.282-0.869-4.241-2-5.69-3.349-0.101-0.093-0.193-0.182-0.283-0.272l0 0z"
      fill="currentColor"
    />
  ),
})
