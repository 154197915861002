import { Box, Button, Flex, IconButton, Show, SimpleGrid } from '@chakra-ui/react'
import type { AuthenticationStatusType } from '@twlvxtwlv/types'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import NextLink from 'next/link'
import { useState } from 'react'
import { UserProfileButton } from './user-profile-button'
import { appendBaseUrlToRoute } from '@twlvxtwlv/utils/src/routing'

type NavigationProps = {
  authenticationStatus?: AuthenticationStatusType
  isCampaign?: boolean
  isHomePage?: boolean
}

export function Navigation({
  authenticationStatus,
  isCampaign = false,
  isHomePage = false,
}: NavigationProps) {
  const { t } = useTranslation(['navigation', 'nft-details', 'common'])

  const [imagePathLeft, setImagePathLeft] = useState('/txt-logo.webp')

  return (
    <>
      <Flex
        direction="row"
        w="100%"
        h={14}
        justifyContent="space-between"
        position="absolute"
        gap={8}
      >
        <Box minW={14} zIndex={2}>
          <Box position="fixed">
            <IconButton
              as={NextLink}
              aria-label={t('aria-label-txt-homepage')}
              variant="ghost"
              rounded="none"
              w={14}
              h={14}
              minW={14}
              bg="txtBlack"
              borderRadius={0}
              _hover={{
                background: 'txtBlack',
              }}
              icon={
                <Image
                  onMouseEnter={() =>
                    setImagePathLeft('/images/twelve-logo-txt-circle-white-spinning.gif')
                  }
                  onMouseLeave={() => setImagePathLeft('/txt-logo.webp')}
                  src={imagePathLeft}
                  alt="txt-logo-white-gif"
                  fill
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              href="https://twlvxtwlv.com/"
              passHref
            />
          </Box>
        </Box>

        <Show above="xl">
          <SimpleGrid
            w="full"
            h="full"
            justifyContent="flex-end"
            alignItems="center"
            gridTemplateColumns="repeat(auto-fit, 180px)"
            gap={4}
            pt={1}
            zIndex={2}
          >
            {!isCampaign ? (
              <Button
                as={NextLink}
                variant="txtWhite"
                size="sm"
                fontSize="11px"
                href="https://twlvxtwlv.com/r/discord"
                target="_blank"
                passHref
              >
                {t('join-discord')}
              </Button>
            ) : null}

            <Button
              as={NextLink}
              variant="txtWhite"
              size="sm"
              fontSize="11px"
              href="https://stage.twlvxtwlv.com"
              target="_blank"
              passHref
            >
              {t('common:navigation-metaverse')}
            </Button>
            <Button
              as={NextLink}
              variant="txtSignalLime"
              size="sm"
              fontSize="11px"
              href={appendBaseUrlToRoute('gallery', isHomePage)}
              passHref
            >
              {t('common:navigation-marketplace')}
            </Button>
            {authenticationStatus === 'unauthenticated' ? (
              <Button
                as={NextLink}
                variant="txtBlack"
                size="sm"
                fontSize="11px"
                href={appendBaseUrlToRoute('login', isHomePage)}
                passHref
              >
                {t('login-sign-up')}
              </Button>
            ) : null}
          </SimpleGrid>
        </Show>

        <Box minW={14}>
          <Box position="fixed" right="0" zIndex={2}>
            <UserProfileButton isHomePage={isHomePage} />
          </Box>
        </Box>
      </Flex>
    </>
  )
}
